import * as React from 'react'

import { BannerPage } from '../components/BannerPage'
import { ContactForm } from '../components/ContactForm'
import { Container } from '../components/Container'
import { Seo } from '../components/Seo'
import { Socials } from '../components/Socials'

const Contact = () => {
  const title = 'Contact Us'
  const description =
    'Feel free to contact me anytime! I will send you a response back in the coming days!'

  return (
    <>
      <Seo title={title} description={description} pathname="contact/" isLanding />

      <BannerPage
        title={title}
        description={description}
        component={
          <>
            <span className="uppercase">Social links</span>
            <Socials />
          </>
        }
      />

      <Container>
        <div className="max-w-xl mx-auto my-20 markdown">
          <h2 className="text-2xl font-medium tracking-tight text-white sm:text-3xl">
            Contact form
          </h2>
          <ContactForm />
        </div>
      </Container>
    </>
  )
}

export default Contact
