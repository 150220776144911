import React, { useState } from 'react'

import classnames from 'classnames'
import Recaptcha from 'react-recaptcha'

import { useSiteMetadata } from '../../hooks'
import { LinkCustom } from '../LinkCustom'

export const ContactForm: React.FC = () => {
  const { formspreeUrl, recaptchaSiteKeyV2 } = useSiteMetadata()
  const [status, setStatus] = useState('')
  const [agree, setAgree] = useState(false)

  const submitForm = (ev) => {
    ev.preventDefault()

    const form = ev.target
    const data = new FormData(form)
    const xhr = new XMLHttpRequest()

    xhr.open(form.method, form.action)
    xhr.setRequestHeader('Accept', 'application/json')
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return
      if (xhr.status === 200) {
        form.reset()
        setStatus('SUCCESS')
      } else {
        setStatus('ERROR')
      }
    }
    xhr.send(data)
  }

  return (
    <>
      <div className="flex text-medium">
        <p>
          Feel free to contact me anytime on
          <a className="ml-3" href="mailto:hello@worldwebstories.com">
            hello@worldwebstories.com
          </a>{' '}
          or you can always send me a message using the form below! Talk to you soon!
        </p>
      </div>

      <form
        method="post"
        onSubmit={submitForm}
        action={formspreeUrl}
        className="grid grid-cols-1 gap-y-6 my-7"
      >
        {status === 'SUCCESS' ? (
          <></>
        ) : (
          <>
            <label htmlFor="name">
              Full name
              <input
                type="text"
                name="name"
                id="name"
                autoComplete="name"
                className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-primary border-gray-300 rounded-md text-black"
                placeholder="John Doe"
                required
              />
            </label>
            <label htmlFor="email">
              Email
              <input
                type="email"
                name="email"
                id="email"
                autoComplete="email"
                className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-primary border-gray-300 rounded-md text-black"
                placeholder="me@example.com"
                required
              />
            </label>
            <label htmlFor="subject">
              What do you want to talk about?
              <input
                type="text"
                name="subject"
                id="subject"
                className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-primary border-gray-300 rounded-md text-black"
                placeholder="Ex: about the last episode"
              />
            </label>
            <label htmlFor="message">
              Your message
              <textarea
                name="message"
                id="message"
                rows="5"
                className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-primary border-gray-300 rounded-md text-black"
                placeholder="Ex: I loved the last episode"
                required
              />
            </label>

            <div>
              <div className="flex items-start">
                <div className="flex-shrink-0">
                  <button
                    type="button"
                    className={classnames(
                      'bg-gray-200 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-gray-300 rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
                      agree ? 'bg-indigo-600' : 'bg-gray-200'
                    )}
                    aria-pressed="false"
                    onClick={() => setAgree(!agree)}
                  >
                    <span className="sr-only">Agree to policies</span>
                    <span
                      aria-hidden="true"
                      className={classnames(
                        'translate-x-0 inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                        agree ? 'translate-x-5' : 'translate-x-0'
                      )}
                    ></span>
                  </button>
                </div>
                <div className="ml-3">
                  <p className="text-base text-gray-500">
                    By selecting this, you agree to the{' '}
                    <LinkCustom
                      to="/privacy-policy/"
                      className="font-medium text-gray-700 underline"
                    >
                      Privacy Policy
                    </LinkCustom>{' '}
                    and{' '}
                    <LinkCustom
                      to="/cookie-policy/"
                      className="font-medium text-gray-700 underline"
                    >
                      Cookie Policy
                    </LinkCustom>
                    .
                  </p>
                </div>
              </div>
            </div>

            <Recaptcha sitekey={recaptchaSiteKeyV2} />
          </>
        )}

        {status === 'ERROR' && (
          <div className="rounded-md bg-red-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <svg
                  className="h-6 w-6 text-red-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <div className="ml-3">
                <p className="text-sm font-medium text-red-800 m-0">
                  Ooops! It seems that something went wrong. Please check all the fields!
                </p>
              </div>
            </div>
          </div>
        )}

        {status === 'SUCCESS' ? (
          <div className="rounded-md bg-green-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <svg
                  className="h-6 w-6 text-green-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              <div className="ml-3">
                <p className="text-sm font-medium text-green-800 m-0">
                  Awesome! We successfully received your message, we will get back to you soon!
                </p>
              </div>
            </div>
          </div>
        ) : (
          <button
            type="submit"
            className={classnames(
              'inline-flex font-sans justify-center py-3 px-6 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600   disabled:bg-opacity-40',
              agree
                ? 'hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                : 'bg-opacity-40 hover:bg-opacity-40 hover:bg-indigo-600 cursor-not-allowed'
            )}
            disabled={!agree}
          >
            Send my message
          </button>
        )}
      </form>
    </>
  )
}
